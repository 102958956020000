/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

import moment from 'moment'

import Vue from 'vue'

import VModal from 'vue-js-modal';

import toastr from 'vue-toastr';

import Datetime from 'vue-datetime'

import 'vue-datetime/dist/vue-datetime.css'

window.moment = require('moment');

window.moment.locale('ru');

window.Vue = require('vue').default;

window.events = new Vue();

// Vue.component('flash', require('./components/Flash.vue').default);

Vue.use(VModal);

Vue.use(toastr);

moment().format();

Vue.use(Datetime)

Vue.use(moment)

Vue.component('ciiu-autocomplete', require('./components/CiiuAutocomplete.vue').default);