<template>
    <div>
        <input type="text" v-model="keyword">
        <input type="hidden" name="ciiu" v-model="ciiu">
        <!--<ul v-if="Ciius.length > 0">
            <li class="list-group-item" v-for="ciiu in Ciius" :key="ciiu.id" v-text="ciiu.value + ':' +ciiu.name" v-on:clock="agregar(ciiu)"></li>
        </ul>-->
        <ul class="list-group" id="lista">
            <li class="list-group-item" v-for="ciiu in Ciius">
                <!--<button type="button" class="btn btn-success" v-on:click="agregar(ciiu)">Asignar</button> <b>{{ ciiu.value + ':' +ciiu.name }}</b>-->
                <div class="ciiu" v-on:click="agregar(ciiu)">
                    <b>{{ ciiu.value + ':' +ciiu.name }}</b>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: ['keyword', 'ciiu'],
    data() {
        return {
            Ciius: []
        };
    },
    watch: {
        keyword(after, before) {
            this.getResults();
        }
    },
    methods: {
        getResults() {
            axios.get('/ciiusearch', { params: { keyword: this.keyword } })
                .then(res => this.Ciius = res.data)
                .catch(error => {});
        },
        agregar: function(ciiu){
            this.keyword = ciiu.value + ':' +ciiu.name;
            this.ciiu = ciiu.id;
            console.log('id='+ciiu.id);
        }
    }
}
</script>
